<template>
  <div class="home">
    <Title />

    <div class="home-container">
      <div class="home-button" @click="toPage()">
        <span class="home-button-text">{{$t("home_btn")}}</span>
      </div>

      <v-btn
        @click="$router.push('/akiya')"
        text
        color="#132d7a"
        class="mt-3"
      >
        <span style="border-bottom: solid 1px #132d7a">{{$t("switch_akiya")}}</span>
      </v-btn>
    </div>

    <Logout />
  </div>
</template>

<script>
import Title from "../components/title/Title.vue"
import Logout from "../components/logout/LogoutButton.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
export default {
  name: 'Home',
  components: {
    Title,
    Logout
  },
    mounted: function() {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      sessionStorage.setItem("login_email", user.email);
    });
    unsubscribe();
  },
  methods: {
    toPage() {
      sessionStorage.setItem('type', 'shigyo')
      this.$router.push('/shigyo/page/1')
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #fafafa;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

  .home-triangle-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  .home-triangle-bottom {
    width: 75%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .home-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 10%;
    z-index: 1;
    height: 80%;
    width: 100%;
    background-image: url("../assets/public/obj_top02.png");
    background-position: center;
    background-size: contain;

    .home-title {
      font-size: max(22px, 2.5vw);
      font-weight: bold;
      color: #132d7a;
    }

    .home-description {
      text-align: center;
      font-size: max(10px, 1.2vw);
      white-space: pre-line;
      font-weight: bold;
      margin-top: 10px;
    }

    .home-button {
      margin-top: 33%;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 30%;
      background-color: #132d7a;
      border-radius: 15px;
      cursor: pointer;
      transition: .5s;

      &:hover {
        opacity: .8;
      }

      .home-button-text {
        color: #fafafa;
        font-size: max(18px, 1.5vw);
        font-weight: bold;
        letter-spacing: 0.8rem;
      }
    }
  }
}
</style>
